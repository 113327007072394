<template>
  <div class="user-content">
    <!-- Tabs -->
    <div class="row">
      <div class="col-6 user-content-header"
           :class="userContentType === 'image' ? 'active' : ''"
           @click="getUserContents('image', 0)">
        <p class="user-content-type big text-white text-center"> Images </p>
      </div>

      <div class="col-6 user-content-header"
           :class="userContentType === 'video' ? 'active' : ''"
           @click="getUserContents('video', 0)">
        <p class="user-content-type big text-white text-center"> Videos </p>
      </div>
    </div>

    <div class="row user-content-body">
      <div class="col">
        <transition name="component-fade" mode="out-in">
          <!-- Images -->
          <image-gallery class="mb-3 mt-3"
                         :border="true"
                         :colImage="colImage"
                         :images="userContents"
                         :reportFeature="true"
                         :downloading="downloading"
                         v-if="userContentType === 'image'"
                         @loadingchange="loadingChange">
            <!-- Load More Slot -->
            <template slot="load">
              <div class="row"
                   v-if="contentCurrentPage !== contentLastPage">
                <div class="col text-center">
                  <b-button class="btn btn-h3-small btn-h3-blue load-more"
                            type="button"
                            @click="loadMoreUserContents">
                    Load More
                  </b-button>
                </div>
              </div>
            </template>
          </image-gallery>

          <!-- Videos -->
          <video-gallery class="mb-3 mt-3"
                         :border="true"
                         :colVideo="colVideo"
                         :videos="userContents"
                         :reportFeature="true"
                         :downloading="downloading"
                         v-if="userContentType === 'video'"
                         @loadingchange="loadingChange">
            <!-- Load More Slot -->
            <template slot="load">
              <div class="row"
                   v-if="contentCurrentPage !== contentLastPage">
                <div class="col text-center">
                  <b-button class="btn btn-h3-small btn-h3-blue load-more"
                            type="button"
                            @click="loadMoreUserContents">
                    Load More
                  </b-button>
                </div>
              </div>
            </template>
          </video-gallery>
        </transition>
      </div>
            <!-- Loading -->
      <loading :active.sync="downloading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
        <rainbow-loader :text="'Downloading...'" />
      </loading>
    </div>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  const ImageGallery = () =>
  import('@/components/user/common/ImageGallery.vue');
  const VideoGallery = () =>
  import('@/components/user/common/VideoGallery.vue');
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name   : 'CreatedContents',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        userContents    : [],
        userContentType : this.$route.params.contentType ?
          this.$route.params.contentType : 'image',
        contentPerPage     : 9,
        contentCurrentPage : 0,
        contentLastPage    : null,
        contentNextPageUrl : null,
        downloading        : false,
      }
    },
    computed : {
      colImage() {
        return this.getPortion(3);
      },
      colVideo() {
        return this.getPortion(3);
      },
    },
    components : {
      ImageGallery,
      VideoGallery,
      Loading,
      RainbowLoader,
    },
    methods : {

      /**
       * Get user contents per type
       *
       * @param type Content type (i.e. image, video)
       * @param currentPage Content's current page (set 0 to reset)
       */
      getUserContents(type, currentPage = this.contentCurrentPage) {
        this.userContentType = type;
        this.$http.get('api/user/content', {
          params : {
            userId  : this.$route.params.userId,
            page    : currentPage + 1,
            perPage : this.contentPerPage,
            type    : type,
          },
        }).then((response) => {
          this.contentCurrentPage = response.data.currentPage;
          this.contentLastPage = response.data.lastPage;

          if (this.contentCurrentPage > 1) {
            const arr1 = this.userContents;
            const arr2 = response.data.data;
            this.userContents = arr1.concat(arr2);
          } else {
            this.userContents = [];
            this.userContents = response.data.data;
          }
        });
      },

      /**
       * Load more User created contents
       */
      loadMoreUserContents() {
        this.getUserContents(this.userContentType);
      },
      loadingChange(data) {
        this.downloading = data;
      },
    },
    mounted() {
      this.loading = true;
      
      // get user content - images and videos
      this.getUserContents(this.userContentType);
    },
  }
</script>


<style lang="scss" scoped>
  @import
    "../../../assets/scss/components/user/public-profile/created-contents";
</style>
